<template>
	<div>
		<div class="center">
			<div class="content">
				<Header/>
				<div class="separate"></div>

				<ProfessionalExperience/>
				<div class="separate"></div>
				
				<AcademicBackground/>
				<div class="separate"></div>

				<TechnicalSkills/>
				<div class="separate"></div>

				<MoreInfo/>
				<div class="separate"></div>

				<Contact/>
			</div>
		</div>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
import AcademicBackground from "@/components/AcademicBackground.vue";
import ProfessionalExperience from "@/components/ProfessionalExperience.vue";
import TechnicalSkills from "@/components/TechnicalSkills.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import Contact from "@/components/Contact.vue";

export default {
	name: "Home-View",
	components: {
		Header,
		AcademicBackground,
		ProfessionalExperience,
		TechnicalSkills,
		MoreInfo,
		Contact,
	},
	
};
</script>
