<template>
  <div>
    <div class="padding">
      <h2>{{ $t("sections_titles.additional_information") }}</h2>
      <hr />
      <div class="line-h-2" v-for="item in MoreInfo" :key="item">
        <div>
          <span class="text-color">&rarr;</span> <span v-html="item.description"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreInfo-Component",
  data() {
    return {
      MoreInfo: [],
    };
  },
  mounted() {
    this.getData(this.$i18n.locale);
  },
  methods: {
    getData(lang) {
      if (lang == "fr") {
        this.MoreInfo = [
          {
            description:
              "Conception et développement de plusieurs applications web dans le domaine de la bioinformatique au sein du département AGC, accessibles sur <a class='link-none custom-link' href=\"https://bioinformatics.um6p.ma/\">bioinformatics.um6p.ma</a>.",
          },
          {
            description:
              "En collaboration avec d'autres étudiants de l'école 1337, nous avons créé le projet 'ft_transcendence', un jeu web de ping-pong en 2D. J'étais chargé du développement de l'interface frontale du jeu.",
          },
          {
            description:
              "Création d'une application web de gestion des cabinets dentaires.",
          },
          { description: "Création d'une application de gestion de pharmacie." },
          {
            description:
              "Implémentation d'une application web de livraison pour les restaurants.",
          },
          {
            description:
              "J'ai géré et assuré la maintenance des serveurs du département AGC.",
          },
          {
            description:
              "Création d'une extension pour navigateur web, 'WhatsApp Web Config' visant à améliorer la confidentialité en ajoutant des outils et des options supplémentaires à WhatsApp Web.",
          },
          {
            description:
              "Création des thèmes WordPress (Steduty, Online Training Platforms).",
          },
          {
            description:
              "Création des extensions WordPress (Online Training Platforms, Switching ADS).",
          },
          {
            description:
              "Réalisation du projet d'enregistrement des absences par empreinte digitale avec Arduino et Application Mobile.",
          },
          { description: "Création d'une application de quiz avec Angular 11." },
          {
            description:
              "Attestation de dessin industriel (SolidWorks ; AutoCAD) de l'École ATC Casablanca.",
          },
        ];
      } else {
        this.MoreInfo = [
          {
            description:
              "Design and development of several web applications in the field of bioinformatics within the AGC department, accessible at <a class='link-none custom-link' href=\"https://bioinformatics.um6p.ma/\">bioinformatics.um6p.ma</a>.",
          },
          {
            description:
              "In collaboration with other students from 1337 school, we created the 'ft_transcendence' project, a 2D web ping-pong game. I was responsible for the frontend development of the game.",
          },
          { description: "Creation of a web application for managing dental clinics." },
          { description: "Creation of a pharmacy management application." },
          {
            description: "Implementation of a web-based restaurant delivery application.",
          },
          { description: "I managed and maintained the servers of the AGC department." },
          {
            description:
              "Creation of a web browser extension, 'WhatsApp Web Config' aimed at improving privacy by adding additional tools and options to WhatsApp Web.",
          },
          {
            description:
              "Creation of WordPress themes (Steduty, Online Training Platforms).",
          },
          {
            description:
              "Creation of WordPress extensions (Online Training Platforms, Switching ADS).",
          },
          {
            description:
              "Completion of the fingerprint-based absence registration project with Arduino and a mobile application.",
          },
          { description: "Creation of a quiz application with Angular 11." },
          {
            description:
              "Industrial drawing certification (SolidWorks; AutoCAD) from ATC Casablanca School.",
          },
        ];
      }
    },
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      if (newVal != oldVal) this.getData(newVal);
    },
  },
};
</script>
