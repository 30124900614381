<template>
  <div>
    <div class="padding text-center">
      <a :href="imgUrl" target="_blank">
        <img
          class="photo"
          src="@/assets/images/mahmoud250.png"
          alt="Image Mahmoud Zakaria"
        />
      </a>
      <h1 class="fw-bold">MAHMOUD ZAKARIA</h1>
      <div class="d-flex justify-content-center">
        <div
          @click="changeLang('en')"
        >
          <img class="img-lang" :class="{ 'active-lang': $i18n.locale == 'en' }" src="@/assets/images/en.png" title="English" />
        </div>
        <div
          
          @click="changeLang('fr')"
        >
          <img class="img-lang"  :class="{ 'active-lang': $i18n.locale == 'fr' }" src="@/assets/images/fr.png" title="French" />
        </div>
      </div>
      <p class=" mt-4">{{ $t("header.title") }}</p>
      <div class="social-icons">
        <ul class="social-list">
          <li v-for="item in socialMedia" :key="item">
            <a class="icon-link" :href="item.link">
              <i :style="item.style" :class="item.class"></i>
            </a>
          </li>
        </ul>
      </div>
      <a class="link-none" href="#contact">
        <button type="button" class="head-button m-2">{{ $t("header.contact_me") }}</button>
      </a>
      <a class="link-none" :href="$t('header.cv_link')" target="_blank">
        <button type="button" class="head-button m-2">{{ $t("header.cv") }}</button>
      </a>
    </div>
  </div>
</template>

<script>
import me from "@/assets/images/mahmoud-zakaria.jpeg";

export default {
  name: "Header-Component",
  data() {
    return {
      imgUrl: me,
      langs: ["en", "fr"],
      socialMedia: [
        {
          class: "fas fa-phone-alt",
          link: "tel:+212666192387",
          style: "font-size: 0.82em",
        },
        {
          class: "fas fa-envelope",
          link: "mailto:zakaria.mahmoud@um6p.ma",
          style: "",
        },
        {
          class: "fab fa-github",
          link: "https://github.com/ZakariaMahmoud",
          style: "",
        },
        {
          class: "fab fa-linkedin-in",
          link: "https://www.linkedin.com/in/zakariamahmoud/",
          style: "",
        },
        {
          class: "fab fa-whatsapp",
          link: "https://wa.me/212666192387",
          style: "",
        },
      ],
    };
  },
  methods: {
    changeLang: function (lang) {
      this.$i18n.locale = lang;
    },
  },
  
};
</script>
