<template>
  <div>
    <div class="padding text-center">
      <h2>{{ $t("sections_titles.contact_me") }}</h2>
      <hr />
      <div class="social-icons mt-5">
        <ul class="social-list">
          <li v-for="item in socialMedia" :key="item">
            <a class="icon-link" :href="item.link">
              <i :style="item.style" :class="item.class"></i>
            </a>
          </li>
        </ul>
      </div>

      <form ref="form" @submit.prevent="checkForm" netlify>
        <input
          type="text"
          class="contact-input"
          v-model="name"
          name="name"
          id="contact"
          placeholder="Full Name"
          required
        />

        <input
          type="text"
          class="contact-input"
          v-model="email"
          name="email"
          placeholder="Email"
        />

        <textarea
          class="contact-input"
          v-model="message"
          name="message"
          cols="10"
          rows="5"
          placeholder="Message"
          required
        ></textarea>

        <br />
        <button type="submit" class="head-button m-2">
          <div v-if="!submitMessage">Send</div>
          <div class="spinner-border" role="status" v-if="submitMessage">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact-Component",
  data() {
    return {
      name: null,
      email: null,
      message: null,
      submitMessage: false,
      socialMedia: [
        {
          class: "fas fa-phone-alt",
          link: "tel:+212666192387",
          style: "font-size: 0.82em",
        },
        {
          class: "fas fa-envelope",
          link: "mailto:zakaria.mahmoud@um6p.ma",
          style: "",
        },
        {
          class: "fab fa-github",
          link: "https://github.com/ZakariaMahmoud",
          style: "",
        },
        {
          class: "fab fa-linkedin-in",
          link: "https://www.linkedin.com/in/zakariamahmoud/",
          style: "",
        },
        {
          class: "fab fa-whatsapp",
          link: "https://wa.me/212666192387",
          style: "",
        },
        {
          class: "fab fa-twitter",
          link: "https://twitter.com/zakariaforwork",
          style: "",
        },
        {
          class: "fab fa-facebook",
          link: "https://www.facebook.com/zakariaforwork",
          style: "",
        },
        {
          class: "fab fa-instagram",
          link: "https://www.instagram.com/zakaria.mahmoud1",
          style: "",
        },
      ],
    };
  },
  methods: {
    checkForm: function () {
      this.submitMessage = true;
      if (this.name && this.email && this.message && this.validEmail(this.email)) {
        this.submit(this);
      } else {
        this.errors = [];

        if (!this.name) {
          this.open("Veuillez renseigner votre nom et prénom", "error", 6000, true);
        }
        if (!this.email) {
          this.open("Veuillez renseigner votre email", "error", 5000, true);
        } else if (!this.validEmail(this.email)) {
          this.open("Votre email n'est pas valide ", "error", 5000, true);
        }
        if (!this.message) {
          this.open("Veuillez renseigner votre message", "error", 4000, true);
        }
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit: function () {
      try {
        this.open("Merci, votre message a bien été envoyé !", "success", 4000, true);
        this.name = "";
        this.email = "";
        this.message = "";
      } catch (error) {
        console.log({ error });
        this.open("Échec de l'envoi du message, veuillez réessayer", "error", 4000, true);
      }
      // Reset form field
    },
    open(message, type, duration, dismissible) {
      this.$toast.open({
        message: message,
        type: type,
        duration: duration,
        dismissible: dismissible,
        position: "top",
      });
      this.submitMessage = false;
    },
  },
};
</script>
