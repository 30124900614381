import { createApp } from 'vue'
import App from './App.vue'

import VueToast from 'vue-toast-notification';
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/css/style.css'
import { createI18n } from 'vue-i18n'
import en from "@/locales/en.json"
import fr from "@/locales/fr.json"


const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages: {
        en: en,
        fr: fr,
    },
})

createApp(App).use(VueToast).use(i18n).mount('#app')

