<template>
  <div>
    <div class="padding">
      <h2>{{ $t("sections_titles.technical_skills") }}</h2>
      <hr />
      <div class="line-h-2" v-for="item in TechnicalSkills" :key="item">
        <div>
          <span class="text-color" v-html="item.title"></span>
          <span v-html="item.description"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnicalSkills-Component",
  data() {
    return {
      TechnicalSkills: [],
    };
  },
  mounted() {
    this.getData(this.$i18n.locale);
  },
  methods: {
    getData(lang) {
      if (lang == "fr") {
        this.TechnicalSkills = [
          {
            title: "Langages de programmation | ",
            description: `C/C++, JavaScript, TypeScript, Python, Php, Java, C#,Dart.`,
          },
          {
            title: "Frameworks et Bibliothèques | ",
            description: `Vue.js , Angular, Laravel, ExpressJS, Flutter, Bootstrap, jQuery.`,
          },
          {
            title: "Systèmes de Gestion de Bases de Données | ",
            description: `MongoDB, PostgreSQL, MySQL, Oracle Database.`,
          },
          {
            title: "Outils de virtualisation et de conteneurisation |  ",
            description: `Docker, VirtualBox, Vmware.`,
          },
          {
            title: "Logiciels de gestion de versions | ",
            description: `Git.`,
          },
          {
            title: "Systèmes d’exploitation | ",
            description: `Ubuntu, Zorin OS, CentOS.`,
          },
          {
            title: "Outils de gestion de projet | ",
            description: `IceScrum, MS Project.`,
          },
          {
            title: "Méthodes d’analyse et de conception | ",
            description: `Merise, UML.`,
          },
          {
            title: "CMS | ",
            description: `WordPress.`,
          },
          {
            title: "Technologies Serveur | ",
            description: `Node.js, Apache.`,
          },
          {
            title: "Simulateurs | ",
            description: `Cisco Packet Tracer, Proteus ISIS.`,
          },
          {
            title: "Logiciels de dessin industriel | ",
            description: `Catia, SolidWorks.`,
          },
        ];
      } else {
        this.TechnicalSkills = [
          {
            title: "Programming Languages | ",
            description: `C/C++, JavaScript, TypeScript, Python, Php, Java, C#,Dart.`,
          },
          {
            title: "Frameworks and Libraries | ",
            description: `Vue.js , Angular, Laravel, ExpressJS, Flutter, Bootstrap, jQuery.`,
          },
          {
            title: "Database Management Systems | ",
            description: `MongoDB, PostgreSQL, MySQL, Oracle Database.`,
          },
          {
            title: "Virtualization and Containerization Tools |  ",
            description: `Docker, VirtualBox, Vmware.`,
          },
          {
            title: "Version Control Software | ",
            description: `Git.`,
          },
          {
            title: "Operating Systems | ",
            description: `Ubuntu, Zorin OS, CentOS.`,
          },
          {
            title: "Project Management Tools | ",
            description: `IceScrum, MS Project.`,
          },
          {
            title: "Analysis and Design Methods | ",
            description: `Merise, UML.`,
          },
          {
            title: "Content Management System | ",
            description: `WordPress.`,
          },
          {
            title: "Server Technologies | ",
            description: `Node.js, Apache.`,
          },
          {
            title: "Simulators | ",
            description: `Cisco Packet Tracer, Proteus ISIS.`,
          },
          {
            title: "Industrial Drawing Software | ",
            description: `Catia, SolidWorks.`,
          },
        ];
      }
    },
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      if (newVal != oldVal) this.getData(newVal);
    },
  },
};
</script>
