<template>
  <div>
    <div class="padding">
      <h2>{{ $t("sections_titles.academic_education") }}</h2>
      <hr />
      <div class="line-h-2" v-for="item in academicBackground" :key="item">
        <div>
          <span class="text-color" v-html="item.year"></span>
          <span v-html="item.description"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Academic-Background-Component",
  data() {
    return {
      academicBackground: [],
    };
  },
  mounted() {
    this.getData(this.$i18n.locale);
  },
  methods: {
    getData(lang) {
      if (lang == "fr") {
        this.academicBackground = [
          {
            year: "2021 – Aujourd'hui | ",
            description:
              "Étudiant à 1337, L'école de programmation de l'Universite Mohammed VI Polythechnique de Ben Guerir, Inscrit dans la formation \"Architecte en Technologies du Numérique\".",
          },
          {
            year: "2020 – 2021 | ",
            description:
              "Licence professionnelle à la Faculté Polydisciplinaire de Sidi Bennour, Filière Informatique et Mathématique Appliquée, Option informatique.",
          },
          {
            year: "2018 – 2020 | ",
            description:
              "Diplôme DUT à l’École Supérieure de Technologie de Sidi Bennour, Filière Génie Informatique.",
          },
          {
            year: "2017 – 2018 | ",
            description:
              "Première Année à L’Institut de Formation aux Métiers de l’Industrie Automobile de Casablanca, Filière Systèmes Automatisés.",
          },
          {
            year: "2017 – 2018 (Décembre &rarr; Mai) | ",
            description:
              "Formation de dessin industriel assisté par ordinateur (Catia et SolidWorks ) à l’Ecole Action Technique Commerciale – Casablanca.",
          },
          {
            year: "2016 - 2017 | ",
            description:
              "Baccalauréat sciences expérimentales, option Sciences Physiques, Lycée Abdallah Ibrahim - Ben Guerir.",
          },
        ];
      } else {
        this.academicBackground = [
          {
            year: "2021 – Present | ",
            description:
              'Student at 1337, the programming school of the Mohammed VI Polytechnic University of Ben Guerir, enrolled in the "Architect in Digital Technologies" program.',
          },
          {
            year: "2020 – 2021 | ",
            description:
              "Professional License at the Polydisciplinary Faculty of Sidi Bennour, Computer and Applied Mathematics Department, Computer Option.",
          },
          {
            year: "2018 – 2020 | ",
            description:
              "DUT Diploma at the Higher School of Technology of Sidi Bennour, Computer Engineering Department.",
          },
          {
            year: "2017 – 2018 | ",
            description:
              "First Year at the Institute of Training for the Automotive Industry in Casablanca, Automated Systems Department.",
          },
          {
            year: "2017 – 2018 (December &rarr; May) | ",
            description:
              "Computer-Aided Industrial Design Training (Catia and SolidWorks) at the School of Technical and Commercial Action - Casablanca.",
          },
          {
            year: "2016 - 2017 | ",
            description:
              "Baccalaureate in experimental sciences, Physics option, Abdallah Ibrahim High School - Ben Guerir.",
          },
        ];
      }
    },
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      if (newVal != oldVal) this.getData(newVal);
    },
  },
};
</script>
