<template>
  <div>
    <div class="padding">
      <h2>{{ $t("sections_titles.professional_experiences") }}</h2>
      <hr />
      <div>
        <div v-for="item in ProfessionalExperience" :key="item">
          <div
            class="d-flex bd-highlight line-h-2"
            v-if="item.date && item.description"
          >
            <div class="p-2 w-100 bd-highlight line-h-2">
              <span class="text-color"> {{ item.date }} | </span> {{ item.description }}
              <div v-if="item.sujet">
                <span class="text-color" v-if="$i18n.locale == 'fr'">&emsp;&rarr; Sujet :</span>
                <span class="text-color" v-if="$i18n.locale == 'en'">&emsp;&rarr; Subject :</span>
                {{ item.sujet }}
              </div>
            </div>
            <div class="p-2 flex-shrink-1 bd-highlight line-h-2 align-self-center">
              <img :src="item.img.src" :alt="item.img.alt" :style="item.img.style" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Professional-Experience-Component",
  data() {
    return {
      ProfessionalExperience: [],
    };
  },
  mounted() {
    this.getData(this.$i18n.locale);
  },
  methods: {
    getData(lang) {
      if (lang == "fr") {
        this.ProfessionalExperience = [
          {
            date: "Du 19/12/2022 à aujourd'hui",
            description: `Contrat CDD d'un an à l'Université Mohammed VI Polytechnique de Ben Guerir en tant que développeur full stack.`,
            sujet: "",
            img: {
              src: require("@/assets/images/um6p.png"),
              alt: "Um6p Ben Guerir",
              style: "",
            },
          },
          {
            date: "Du 18/10/2021 au 23/03/2022",
            description: `Stage en tant que professeur d'enseignement supérieur assistant au sein de l'école privé Sagi Ben guerir.`,
            sujet: "",
            img: {
              src: require("@/assets/images/sagi.png"),
              alt: "Sagi Ben Guerir",
              style: "",
            },
          },
          {
            date: "Du 01/04/2021 au 01/06/2021",
            description: `Stage de fin d’études au sein de service Développement à Ziryab Tec.`,
            sujet: "Implémentation d'une plateforme de formation en ligne avec Laravel.",
            img: {
              src: require("@/assets/images/ziryabtec.png"),
              alt: "Ziryab Tec",
              style: "padding-right: 19px",
            },
          },
          {
            date: "Du 01/04/2020 au 01/06/2020",
            description: `Stage de fin d’études au sein de service Développement à Ziryab Tec.`,
            sujet:
              "Application mobile de gestion des données touristique avec Django et Django REST Framework.",
            img: {
              src: require("@/assets/images/ziryabtec.png"),
              alt: "Ziryab Tec",
              style: "padding-right: 19px",
            },
          },
          {
            date: "Du 01/08/2019 au 30/08/2019",
            description: `Stage d’initiation au sein de service Développement RH à OCP BEN GUERIR.`,
            sujet: "Réalisation d'une application web de gestion des cabinets dentaires.",
            img: {
              src: require("@/assets/images/ocp.png"),
              alt: "OCP Ben Guerir",
              style: "padding-right: 22px",
            },
          },
          {
            date: "Du 05/03/2018 au 06/05/2018",
            description: `Stage d’operateur au sein de service Maintenance à LEONI CASABLANCA`,
            sujet: "",
            img: {
              src: require("@/assets/images/leoni.png"),
              alt: "LEONI",
              style: "",
            },
          },
          {
            date: "Du 01/11/2017 au 01/01/2018",
            description: `Stage d’observation au sein de service Maintenance à LEONI CASABLANCA.`,
            sujet: "",
            img: {
              src: require("@/assets/images/leoni.png"),
              alt: "LEONI",
              style: "",
            },
          },
        ];
      } else {
        this.ProfessionalExperience = [
          {
            date: "From 12/19/2022 to Present",
            description:
              "One-year fixed-term contract at the University Mohammed VI Polytechnic of Ben Guerir as a full-stack developer.",
            sujet: "",
            img: {
              src: require("@/assets/images/um6p.png"),
              alt: "Um6p Ben Guerir",
              style: "",
            },
          },
          {
            date: "From 10/18/2021 to 03/23/2022",
            description:
              "Internship as an assistant professor in Sagi Ben Guerir private school.",
            sujet: "",
            img: {
              src: require("@/assets/images/sagi.png"),
              alt: "Sagi Ben Guerir",
              style: "",
            },
          },
          {
            date: "From 04/01/2021 to 06/01/2021",
            description: "Final internship at the Development Department of Ziryab Tec.",
            sujet: "Implementation of an online training platform using Laravel.",
            img: {
              src: require("@/assets/images/ziryabtec.png"),
              alt: "Ziryab Tec",
              style: "padding-right: 19px",
            },
          },
          {
            date: "From 04/01/2020 to 06/01/2020",
            description: "Final internship at the Development Department of Ziryab Tec.",
            sujet:
              "Mobile application for managing tourist data using Django and Django REST Framework.",
            img: {
              src: require("@/assets/images/ziryabtec.png"),
              alt: "Ziryab Tec",
              style: "padding-right: 19px",
            },
          },
          {
            date: "From 08/01/2019 to 08/30/2019",
            description:
              "Initiation internship at the HR Development Department of OCP BEN GUERIR.",
            sujet: "Development of a web application for managing dental clinics.",
            img: {
              src: require("@/assets/images/ocp.png"),
              alt: "OCP Ben Guerir",
              style: "padding-right: 22px",
            },
          },
          {
            date: "From 03/05/2018 to 05/06/2018",
            description:
              "Operator internship in the Maintenance Department at LEONI CASABLANCA.",
            sujet: "",
            img: {
              src: require("@/assets/images/leoni.png"),
              alt: "LEONI",
              style: "",
            },
          },
          {
            date: "From 11/01/2017 to 01/01/2018",
            description:
              "Observation internship in the Maintenance Department at LEONI CASABLANCA.",
            sujet: "",
            img: {
              src: require("@/assets/images/leoni.png"),
              alt: "LEONI",
              style: "",
            },
          },
        ];
      }
    },
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      if (newVal != oldVal) this.getData(newVal);
    },
  },
};
</script>
